import { EventBus } from '@adeo/kobi--front-helpers';
import { META_TAG_ENUMS } from './metaTag.enum';
export default class MetaTagEventBus {
  init() {
    try {
      window.MetaTagEventBus = new EventBus(META_TAG_ENUMS.BUS_EVENT);
    } catch (error) {
      throw `Error : ${error}`;
    }
  }
}
import JsonLdEventTriggerModule from './jsonLdEventTrigger.module';
import { META_TAG_ENUMS } from '../includes/metaTag.enum';
import JsonLdAdapter from './jsonLdAdapter.class';
class JsonLdModule {
  constructor() {
    this.scriptOnHead = {
      isPresent: false
    };
    this.getDataFromJsonLdScriptHead = () => this.scriptOnHead.element ? JSON.parse(this.scriptOnHead.element.innerHTML) : {};
  }
  init(resource) {
    try {
      this.resource = resource;
      this.eventTrigger = new JsonLdEventTriggerModule();
      this.eventTrigger.init();
      this.initJsonLdScriptInHead();
      this.listen();
    } catch (error) {
      throw `Error : ${error}`;
    }
  }
  listen() {
    this.eventTrigger.listener(data => {
      if (data) {
        this.prepare(data);
        const stringifyDataAdapted = this.adapt();
        this.inject(stringifyDataAdapted);
      }
    });
  }
  prepare(data) {
    this.data = Object.assign(Object.assign(Object.assign({}, this.data), data), this.getDataFromJsonLdScriptHead());
  }
  adapt() {
    const adapterInstance = new JsonLdAdapter();
    return adapterInstance.adapt(this.data) || '';
  }
  inject(jsonLdDataStringify) {
    if (this.scriptOnHead.isPresent && this.scriptOnHead.element) {
      this.scriptOnHead.element.innerHTML = jsonLdDataStringify;
    } else {
      const jsonLdHtmlElement = this.createJsonLdScriptElement();
      jsonLdHtmlElement.innerHTML = jsonLdDataStringify;
      document.body.insertAdjacentElement(META_TAG_ENUMS.INJECT_POSITION, jsonLdHtmlElement);
    }
  }
  createJsonLdScriptElement() {
    let jsonLdElement = document.getElementById(META_TAG_ENUMS.SCRIPT_SELECTOR_ID);
    if (!jsonLdElement) {
      jsonLdElement = document.createElement(META_TAG_ENUMS.SCRIPT_TAG);
      jsonLdElement.type = META_TAG_ENUMS.SCRIPT_TYPE;
      jsonLdElement.id = META_TAG_ENUMS.SCRIPT_SELECTOR_ID;
    }
    return jsonLdElement;
  }
  initJsonLdScriptInHead() {
    this.scriptOnHead.element = document.getElementById(`${META_TAG_ENUMS.SCRIPT_HEAD_ID}${this.resource}`);
    this.scriptOnHead.isPresent = !!this.scriptOnHead.element;
  }
}
export default JsonLdModule;
import { AUTHORIZED_RESOURCE, RESOURCE_ENUMS } from './resource.constants';
export class ResourceMiddleWare {
  constructor() {
    this.isResourceHaveAuthorization = () => AUTHORIZED_RESOURCE.includes(this.resource);
    try {
      this.resourceElement = document.getElementById(`${RESOURCE_ENUMS.RESOURCE_TYPE_SELECTOR}`);
      this.resource = this.resourceElement ? JSON.parse(this.resourceElement.innerHTML).resourceType : 'UNAUTHORIZED';
    } catch (error) {
      throw `Error : ${error}`;
    }
  }
}
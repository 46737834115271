import JsonLdModule from './jsonld/jsonLd.module';
import MetaTagEventBus from './includes/metaTagEventBus.class';
import { ResourceMiddleWare } from './resource/resource.middleware';
class MetaTagModule {
  init(resourceMiddleWare) {
    try {
      this.initializeMetaTagEventBusModule();
      if (resourceMiddleWare.isResourceHaveAuthorization()) {
        this.initializeJsonLdModule(resourceMiddleWare.resource);
      }
    } catch (error) {
      throw `Error : ${error}`;
    }
  }
  initializeMetaTagEventBusModule() {
    const metaTagEventbus = new MetaTagEventBus();
    metaTagEventbus.init();
    return metaTagEventbus;
  }
  initializeJsonLdModule(resource) {
    const jsonLdModule = new JsonLdModule();
    jsonLdModule.init(resource);
  }
}
const metaTagModuleInstance = new MetaTagModule();
metaTagModuleInstance.init(new ResourceMiddleWare());